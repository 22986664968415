import { lazy, Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/scss/bootstrap.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import "./assets/css/main.scss";
import "./assets/css/responsive.scss";
import "./assets/css/print.scss";
import "suneditor/dist/css/suneditor.min.css";
import { Home } from "./pages/Home";

import { Contact } from "./pages/Contact";
import { Support } from "./pages/Support";
import { Login } from "./pages/Login";
import { Help } from "./pages/Help";
import { Community } from "./pages/Community";
import { Adapt } from "./pages/Adapt";

import { Find } from "./pages/Find";

import { FindResults } from "./pages/FindResults";
import { BuildTemplate } from "./pages/BuildTemplate";
import { Collaborate } from "./pages/Collaborate";
import { CollaborateDetails } from "./pages/CollaborateDetails";
import { CollaborateChat } from "./pages/CollaborateChat";
import { CommunityFriends } from "./pages/CommunityFriends";
import { CommunityFindFriends } from "./pages/CommunityFindFriends";
import { CommunityShareFiles } from "./pages/CommunityShareFiles";
import { CommunitySelectedShareFiles } from "./pages/CommunitySelectedShareFiles";

import { Adaptations } from "./pages/Adaptations";
import { ClassDetail } from "./pages/ClassDetail";

import { UserInfo } from "./store";
import { useRecoilState, useRecoilValue } from "recoil";
import ReactLoading from "react-loading";
import { ClassEdit } from "./pages/ClassEdit";
import { FolderEdit } from "./pages/FolderEdit";
import { FolderNew } from "./pages/FolderNew";
import PublishActivity from "./pages/PublishActivity";
import { TemplateDetail } from "./pages/TemplateDetail";
import { TemplateView } from "./pages/TemplateView";
import { ActivitySelect } from "./pages/ActivitySelect";
import { BrowserView, MobileView } from "react-device-detect";
import TutorialView from "./pages/TutorialView";
import EditUnit from "./pages/EditUnit";
import ViewCourse from "./pages/ViewCourse";
import ViewUnit from "./pages/ViewUnit";
import ViewCollection from "./pages/ViewCollection";
import EditSubCollection from "./pages/EditSubCollection";
import { LoginWithEmail } from "./pages/LoginWithEmail";
import { AddInfoUserModal } from "./components/modals/AddInfoUserModal";
import { UpdateInfoUser } from "./models";
import { useMemberInfo, useUserData } from "./services/Query";
const Signup = lazy(() => import("./pages/Signup"));
const FolderDetail = lazy(() => import("./pages/FolderDetail"));
const Verify = lazy(() => import("./pages/Verify"));
const Profile = lazy(() => import("./pages/Profile"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Resend = lazy(() => import("./pages/Resend"));
const Ramp = lazy(() => import("./pages/Ramp"));
const About = lazy(() => import("./pages/About"));
const Build = lazy(() => import("./pages/Build"));
const LessonView = lazy(() => import("./pages/LessonView"));
const BuildLesson = lazy(() => import("./pages/BuildLesson"));
const AddLesson = lazy(() => import("./pages/AddLesson"));
const EditCourse = lazy(() => import("./pages/EditCourse"));
const EditCollection = lazy(() => import("./pages/EditCollection"));
const renderLoader = () => (
  <div className="w-100 text-center py-4">
    <ReactLoading
      type="spinningBubbles"
      className="mx-auto my-auto"
      height={"70px"}
      width={"70px"}
      color="#666666"
    />
  </div>
);
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 120,
        refetchOnMount: "always",
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
      },
    },
  });
  
  const [userinfo, setUserInfo] = useRecoilState(UserInfo);
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShow = () => {
    if (!userinfo?.member_FirstName && !userinfo?.member_LastName) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  }
  useEffect(() => {
    handleShow();
  }, [userinfo]);
  return (
    <>
      <BrowserView>
        <QueryClientProvider client={queryClient}>
          {userinfo && (
            <AddInfoUserModal
              show={showModal}
              onHide={handleCloseModal}
              setUserInfo={setUserInfo}
            />
          )}

          <ThemeProvider breakpoints={["sm", "xs", "xxs"]}>
            <Suspense fallback={renderLoader()}>
              <Router>
                <Routes>
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/support" element={<Support />} />

                  {/* <Route path="/signup" element={<Signup />} /> */}
                  {/* <Route path="/resend" element={<Resend />} /> */}
                  <Route path="/verify/:tokenId" element={<Verify />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/login-email" element={<LoginWithEmail />} />

                  <Route path="/help" element={<Help />} />
                  <Route
                    path="/build/template/view/:activityId"
                    element={<TemplateView />}
                  />
                  {userinfo && (
                    <>
                      <Route path="/tutorial" element={<TutorialView />} />
                      <Route path="/ramp" element={<Ramp />} />
                      <Route path="/dashboard/*" element={<Dashboard />} />
                      <Route path="/profile/:memberId" element={<Profile />} />
                      <Route path="/community" element={<Community />} />
                      <Route path="/adapt" element={<Adapt />} />
                      <Route path="build" element={<Build />} />
                      <Route
                        path="/build/template"
                        element={<BuildTemplate />}
                      />
                      <Route path="collaborate" element={<Collaborate />} />
                      <Route
                        path="/community/friends"
                        element={<CommunityFriends />}
                      />
                      <Route
                        path="/community/find_friends"
                        element={<CommunityFindFriends />}
                      />
                      <Route
                        path="/community/sharefiles"
                        element={<CommunityShareFiles />}
                      />
                      <Route
                        path="/community/selectedsharefiles"
                        element={<CommunitySelectedShareFiles />}
                      />
                      <Route
                        path="/build/collaborate/detail"
                        element={<CollaborateDetails />}
                      />
                      <Route
                        path="/build/collaborate/chat"
                        element={<CollaborateChat />}
                      />
                      <Route
                        path="/build/View/Lesson/:unit_id/:lesson_id"
                        element={<LessonView />}
                      />
                      <Route
                        path="/add/Lesson/:unit_id"
                        element={<AddLesson />}
                      />
                      <Route
                        path="/Edit/Lesson/:lesson_id"
                        element={<BuildLesson />}
                      />
                      <Route
                        path="/build/template/detail/:preview"
                        element={<TemplateDetail />}
                      />

                      <Route
                        path="/build/select/activity"
                        element={<ActivitySelect />}
                      />

                      <Route
                        path="/build/public/activity"
                        element={<PublishActivity />}
                      />
                      <Route
                        path="/adaptations/:activityId"
                        element={<Adaptations />}
                      />
                      <Route
                        path="/classdetail/:clsId"
                        element={<ClassDetail />}
                      />
                      <Route
                        path="/build/classedit/:clsId"
                        element={<ClassEdit />}
                      />
                      <Route
                        path="/build/folderedit/:folderId"
                        element={<FolderEdit />}
                      />
                      <Route
                        path="/build/foldernew/:parentId/:folderId"
                        element={<FolderNew />}
                      />
                      <Route
                        path="/folderdetail/:folderId"
                        element={<FolderDetail />}
                      />
                      <Route path="/find" element={<Find />} />
                      <Route path="/find/results" element={<FindResults />} />
                      <Route
                        path="/edit_course/:course_id"
                        element={<EditCourse />}
                      />
                      <Route
                        path="/edit_folder/:folder_id"
                        element={<EditCollection />}
                      />
                      <Route
                        path="/edit_unit/:course_id/:unit_id"
                        element={<EditUnit />}
                      />
                      <Route
                        path="/edit_folder/:folder_id/:sub_folder_id"
                        element={<EditSubCollection />}
                      />
                      <Route path="/view/course/:id" element={<ViewCourse />} />
                      <Route path="/view/unit/:id" element={<ViewUnit />} />
                      <Route
                        path="/view/collection/:id"
                        element={<ViewCollection />}
                      />
                    </>
                  )}
                  <Route path="*" element={<Home />} />
                </Routes>
              </Router>
            </Suspense>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserView>
      <MobileView>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider breakpoints={["sm", "xs", "xxs"]}>
            <Suspense fallback={renderLoader()}>
              <Router>
                <Routes>
                  <Route path="/" element={<Signup />} />
                  <Route path="/login" element={<Login />}></Route>
                  <Route
                    path="/login-email"
                    element={<LoginWithEmail />}
                  ></Route>

                  <Route path="/resend" element={<Resend />} />
                  <Route path="/verify/:tokenId" element={<Verify />} />
                  {userinfo && (
                    <>
                      <Route path="/tutorial" element={<TutorialView />} />
                      <Route path="/ramp" element={<Ramp />} />
                      <Route path="/dashboard/*" element={<Dashboard />} />
                      <Route path="/profile/:memberId" element={<Profile />} />
                      <Route path="/community" element={<Community />} />
                      <Route path="/adapt" element={<Adapt />} />
                      <Route path="build" element={<Build />} />
                      <Route
                        path="/build/template"
                        element={<BuildTemplate />}
                      />
                      <Route path="collaborate" element={<Collaborate />} />
                      <Route
                        path="/build/View/Lesson/:unit_id/:lesson_id"
                        element={<LessonView />}
                      />
                      <Route
                        path="/community/friends"
                        element={<CommunityFriends />}
                      />
                      <Route
                        path="/community/find_friends"
                        element={<CommunityFindFriends />}
                      />
                      <Route
                        path="/community/sharefiles"
                        element={<CommunityShareFiles />}
                      />
                      <Route
                        path="/community/selectedsharefiles"
                        element={<CommunitySelectedShareFiles />}
                      />
                      <Route
                        path="/build/collaborate/detail"
                        element={<CollaborateDetails />}
                      />
                      <Route
                        path="/build/collaborate/chat"
                        element={<CollaborateChat />}
                      />
                      <Route
                        path="/build/View/Lesson/:lesson_id"
                        element={<LessonView />}
                      />
                      <Route
                        path="/add/Lesson/:unit_id"
                        element={<AddLesson />}
                      />
                      <Route
                        path="/Edit/Lesson/:lesson_id"
                        element={<BuildLesson />}
                      />
                      <Route
                        path="/build/template/detail/:preview"
                        element={<TemplateDetail />}
                      />

                      <Route
                        path="/build/select/activity"
                        element={<ActivitySelect />}
                      />

                      <Route
                        path="/build/public/activity"
                        element={<PublishActivity />}
                      />
                      <Route
                        path="/adaptations/:activityId"
                        element={<Adaptations />}
                      />

                      <Route
                        path="/build/foldernew/:parentId/:folderId"
                        element={<FolderNew />}
                      />
                      <Route
                        path="/folderdetail/:folderId"
                        element={<FolderDetail />}
                      />
                      <Route path="/find" element={<Find />} />
                      <Route path="/find/results" element={<FindResults />} />
                    </>
                  )}
                </Routes>
              </Router>
            </Suspense>
          </ThemeProvider>
        </QueryClientProvider>
      </MobileView>
    </>
  );
}

export default App;
