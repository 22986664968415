import { FormEvent, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import {
  FormState,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import ReactLoading from "react-loading";

export type FormData = {
  email: string;
  code: string;
};

type Props = {
  formType: "email" | "code";
  onSubmit: (data: FormData) => void;
  register: UseFormRegister<FormData>;
  handleSubmit: UseFormHandleSubmit<FormData>;
  errors: FormState<FormData>["errors"];
  reset: () => void;
  loading: boolean;
};

export const AuthForm = (props: Props) => {
  const { formType, onSubmit, register, handleSubmit, errors, reset, loading } =
    props;
    const renderLoader = () => (
      <div className="w-100 text-center py-4"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={"70px"}
          width={"70px"}
          color="#666666"
        />
      </div>
    );
  if (formType === "email") {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold form-label">Email</Form.Label>
          <Form.Control
            type="email"
            {...register("email", { required: "Email is required" })}
            placeholder="Enter your email"
            isInvalid={!!errors.email}
            autoFocus
            className="p-3"
          />
        </Form.Group>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <button
            type="submit"
            className={`border_warn mt-3 
               back_btn
             rounded_3 p-3 fs-6 fnt_robo fw-bold text-white`}
          >
            Submit
          </button>
        </div>
        {loading && renderLoader()}
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold form-label">Enter Code</Form.Label>
        <Form.Control
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          {...register("code", {
            required: "Code is required",
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter only numbers",
            },
          })}
          className="p-3"
          placeholder="Enter Code"
          autoFocus
          isInvalid={!!errors.code}
        />
      </Form.Group>
      <div className="d-flex justify-content-center mt-3 mb-3">
        <button
          type="submit"
          className="border_warn mt-3 back_btn rounded_3 py-3 px-3 fs-6 fnt_robo fw-bold text-white"
        >
          Login
        </button>
      </div>
      {loading && renderLoader()}
    </Form>
  );
};
