import React, { useEffect } from "react";
import { Container, Form, Modal, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { UpdateInfoUser } from "../../models";
import {
  useCountries,
  useMemberInfo,
  useProfileData,
} from "../../services/Query";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../../store";
import ReactLoading  from 'react-loading';
interface Props {
  show?: boolean;
  onHide?: () => void;
  setUserInfo: (userInfo: any) => void;
  
}
interface FormValues {
  firstName: string;
  lastName: string;
  schoolName: string;
  schoolType: string;
  country: string;
  province:string;
  city:string;
}
export const typeSchoolArry = [
  "Public School",
  "Private School",
  "International School",
  "College/University",
  "Homeschool",
  "Microschool",
  "Other"
];

export const AddInfoUserModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const userinfo = useRecoilValue(UserInfo);
  const { mutate: onUpdateprofile ,isLoading:updateLoading} = useProfileData();
  const { data: Countries } = useCountries();
  const memberID = String(userinfo?.member_Id);
  const { data: memberData, refetch:getMemberData } = useMemberInfo(memberID);
  const watchTypeOfSchool = watch("schoolType");
  const watchCountry = watch("country");
  const getStates = (countryName: string | undefined): string[] => {
    if (Countries) {
      const country = Countries.filter((it) => it.name === countryName);
      if (country && country.length) {
        const sts = country[0].states.map((it) => it.name);
        return sts;
      }
    }
    return [];
  };
  useEffect(() => {
    if (memberData) {
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("schoolType", memberData?.member_SchoolType);
      setValue("schoolName", memberData?.member_SchoolName);
      setValue("country", memberData?.member_Country);
      setValue("province",memberData?.member_Province);
      setValue("city",memberData?.member_City)
    }
  }, [memberData]);
  const onSubmit: SubmitHandler<UpdateInfoUser> = async (data) => {
    const formData: FormValues = {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      schoolType: data.schoolType ?? "",
      schoolName: data.schoolName ?? "",
      country: data.country ?? "",
      province:data.province ??"",
      city:data.city ??""

    };
    if (memberData) {
      onUpdateprofile(
        { memberId: memberID, formData: JSON.stringify(formData) },
        {
          onSuccess: (res) => {
            props.setUserInfo(res);
            getMemberData();
            if (props.onHide) {
              props.onHide();
            }
          },
        }
      );
    }
  };
  const renderLoader = () => (
    <div className="w-100 text-center py-4"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={"70px"}
        width={"70px"}
        color="#666666"
      />
    </div>
  );
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      className="dashboard_video"
      centered={true}
      keyboard={false}
      backdrop="static"
      
    >
      <Modal.Header>
        <Modal.Title>Update Information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                First Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("firstName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.firstName}
                autoFocus
              />
              {errors.firstName && (
                <small className="text-danger">
                  First name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                Last Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("lastName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.lastName}
              />
              {errors.lastName && (
                <small className="text-danger">
                  Last name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                School Type *
              </Form.Label>
              <Form.Select {...register("schoolType")}>
                {typeSchoolArry.map((it, idx) => (
                  <option value={it} key={idx}>
                    {it}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                School Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("schoolName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.schoolName}
              />
              {errors.schoolName && (
                <small className="text-danger">
                  School name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">Country *</Form.Label>
              <Form.Select {...register("country")}>
                {Countries &&
                  Countries.map((it, idx) => (
                    <option value={it.name} key={idx}>
                      {it.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
              State / Province *
              </Form.Label>
              <Form.Select {...register("province")}>
                <option value=""></option>
                  {getStates(watch("country")).map((it, idx) => (
                  <option value={it} key={idx}>
                    {it}
                  </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                City *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("city", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.schoolName}
              />
              {errors.schoolName && (
                <small className="text-danger">
                  city must be at least 3 characters long.
                </small>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center gap-3">
              <button
                type="submit"
                disabled={!isValid || !watchTypeOfSchool || !watchCountry}
                className="border_warn mt-3 bg-white rounded_3 py-2 px-4 fs-6"
              >
                Save
              </button>
            </div>
            {updateLoading && renderLoader()}
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
