import { useMutation, useQuery } from "react-query";
import {
  ActivitiesPage,
  Activity,
  ActivityMappingDto,
  AdaptInfo,
  AttachData,
  Classes,
  CollectionItem,
  findRequest,
  Folder,
  ICountry,
  Lesson,
  LessonDto,
  LessonInfoDto,
  LessonSection,
  LessonsPage,
  LoginRes,
  Member,
  Prompt,
  UploadItems,
} from "../models";
import HttpService from "./HttpServices";
import { isGuidValidate } from "./Utils";
import {
  CollectionMembers,
  Course,
  CourseMembers,
  Units,
} from "../models/Course";
import { FolderItem, FolderItemDto } from "../models/FolderItem";
/***
 * react query objects
 */
interface ClassParam {
  classId: string;
  data: string;
}
interface ActivityParam {
  memberId: string;
  formData: string;
}
interface MemberParam {
  memberId: string;
  formData: string;
}
interface LessonParam {
  memberId: string;
  lessonData: LessonDto;
}
interface AdaptParam {
  memberId: string;
  orgActivityId: string;
  classId: string;
}
interface deleteParam {
  memberId: string;
  lessonId: string;
}
interface AddFolder {
  folderId: string;
  itemId: string;
  isActivity: boolean;
}
type MemberCodeDto = {
  Email: string;
  Code: string;
};
export const useLogin = () => {
  return useMutation<Member, Error, string>(
    "login",
    async (data) => {
      return await HttpService.login(data);
    },
    {
      retry: false,
    }
  );
};
export const useSignup = () => {
  return useMutation<any, Error, string>(
    "signup",
    async (formdata) => {
      return await HttpService.signup(formdata);
    },
    {
      retry: false,
    }
  );
};

export const useSaveClass = () => {
  return useMutation<Classes, Error, ClassParam>(
    "save_class",
    async ({ classId, data }: ClassParam) => {
      return await HttpService.updateClassInfo(classId, data);
    },
    {
      retry: false,
    }
  );
};
export const useSaveActivity = () => {
  return useMutation<Activity, Error, string>(
    "save_activity",
    async (formData: string) => {
      return await HttpService.saveActivity(formData);
    },
    {
      retry: false,
    }
  );
};
export const useSaveFileDrive = () => {
  return useMutation<any, Error, string>(
    "save_image_activity",
    async (formData: string) => {
      return await HttpService.saveFileActivity(formData);
    },
    {
      retry: false,
    }
  )
};
export const useGetFolder = (folderId: string | undefined) => {
  return useQuery<Folder, Error>(
    ["folder_info", folderId],
    async () => {
      return await HttpService.getFolderInfo(folderId);
    },
    {
      retry: false,
    }
  );
};
export const useGetClass = (classId: string | undefined) => {
  return useQuery<Classes, Error>(
    ["class_info", classId],
    async () => {
      return await HttpService.getClassInfo(classId);
    },
    {
      retry: false,
    }
  );
};
export const useRecentActivities = (memberId: string | undefined) => {
  return useQuery<Activity[], Error>(
    ["activitis", memberId],
    async () => {
      if (memberId) return await HttpService.recentActivities(memberId);
      return [];
    },
    {
      retry: false,
    }
  );
};
export const useMyActivities = (memberId: string | undefined, page: number) => {
  return useQuery<ActivitiesPage | undefined, Error>(
    ["recent_activitis", memberId, page],
    async () => {
      if (memberId) return await HttpService.getActivities(memberId, page);
      return undefined;
    },
    {
      retry: false,
    }
  );
};
export const useAllActivities = () => {
  return useQuery<Activity[] | undefined, Error>(
    ["all_activitis"],
    async () => {
      return await HttpService.allActivities();
    },
    {
      retry: false,
    }
  );
};
export const useFindActivities = (
  memberId: string | undefined,
  findData: findRequest
) => {
  return useQuery<Activity[], Error>(
    ["find_activitis", memberId, findData],
    async () => {
      if (memberId)
        return await HttpService.findActivities(
          memberId,
          JSON.stringify(findData)
        );
      return [];
    },
    {
      retry: false,
      cacheTime: 0,
    }
  );
};
export const useFoloderActivities = (folderId: string | undefined) => {
  return useQuery<Activity[], Error>(
    ["folder_activitis", folderId],
    async () => {
      if (folderId) return await HttpService.getFolderActivities(folderId);
      return [];
    },
    {
      retry: false,
      enabled: false,
    }
  );
};
export const useClassActivities = (classId: string | undefined) => {
  return useQuery<Activity[], Error>(
    ["class_activitis", classId],
    async () => {
      if (classId) return await HttpService.getClassActivities(classId);
      return [];
    },
    {
      retry: false,
      enabled: false,
    }
  );
};
export const useAdaptActivity = () => {
  return useMutation<Activity, Error, string>(
    "adapt_activity",
    async (orgActivityId) => {
      return await HttpService.adaptActivity(orgActivityId);
    },
    {
      retry: false,
    }
  );
};
export const usePublishActivity = () => {
  return useMutation<Activity, Error, string>(
    "publish_activity",
    async (activityId) => {
      return await HttpService.publishActivity(activityId);
    },
    {
      retry: false,
    }
  );
};
export const useProfileData = () => {
  return useMutation<Member, Error, MemberParam>(
    "update_profile",
    async ({ memberId, formData }: MemberParam) => {
      return await HttpService.updateProfileData(memberId, formData);
    },
    {
      retry: false,
    }
  );
};
export const useSendFeedback = () => {
  return useMutation<any, Error, FormData>(
    "send_feedback_email",
    async (formdata: FormData) => {
      return await HttpService.sendFeedback(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useAddFolder = () => {
  return useMutation<any, Error, AddFolder>(
    ["add_folder_lesson"],
    async ({ folderId, itemId, isActivity }: AddFolder) => {
      if (isActivity) {
        return await HttpService.addToFolderActivity(folderId, itemId);
      } else {
        return await HttpService.addToFolderLesson(folderId, itemId);
      }
    },
    {
      retry: false,
    }
  );
};
export const useClassFolder = () => {
  return useMutation<any, Error, AddFolder>(
    ["add_class_lesson"],
    async ({ folderId, itemId, isActivity }: AddFolder) => {
      if (isActivity) {
        return await HttpService.addToClassAcitivity(folderId, itemId);
      } else {
        return await HttpService.addToClassLesson(folderId, itemId);
      }
    },
    {
      retry: false,
    }
  );
};

export const useProfileImage = () => {
  return useMutation<Member, Error, FormData>(
    "save_profile_image",
    async (formData: FormData) => {
      return await HttpService.updateUserImageData(formData);
    },
    {
      retry: false,
    }
  );
};
export const useUserData = () => {
  return useMutation<LoginRes, Error, string>(
    "update_user_data",
    async (formData: string) => {
      return await HttpService.updateUserData(formData);
    },
    {
      retry: false,
    }
  );
};

export const useFolderData = () => {
  return useMutation<Folder, Error, ActivityParam>(
    "update_folder_data",
    async ({ memberId, formData }: ActivityParam) => {
      return await HttpService.updateFolderInfo(memberId, formData);
    },
    {
      retry: false,
    }
  );
};
export const useMemberUse = () => {
  return useMutation<Member, Error, string>(
    "update_user_data",
    async (memberId: string) => {
      return await HttpService.checkFirstUser(memberId);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteActivity = () => {
  return useMutation<Member, Error, string>(
    "delete_activity",
    async (actId: string) => {
      return await HttpService.deleteActivity(actId);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteLesson = () => {
  return useMutation<Member, Error, deleteParam>(
    "delete_lesson",
    async ({ memberId, lessonId }: deleteParam) => {
      return await HttpService.deleteLesson(lessonId);
    },
    {
      retry: false,
    }
  );
};
export const useAttachFiles = () => {
  return useMutation<UploadItems, Error, FormData>(
    "attach_files",
    async (formData: FormData) => {
      return await HttpService.uploadAttachFiles(formData);
    },
    {
      retry: false,
    }
  );
};
export const useCreateDoc = (memberId: string | undefined) => {
  return useQuery<string | undefined, Error>(
    ["create_blank_doc", memberId],
    async () => {
      return await HttpService.createBlankDoc(memberId);
    },
    {
      retry: false,
      enabled: false,
    }
  );
};
export const useSaveLesson = () => {
  return useMutation<Lesson, Error, LessonDto>(
    ["save_lesson"],
    async (lessonData) => {
      return await HttpService.saveLesson(JSON.stringify(lessonData));
    },
    {
      retry: false,
    }
  );
};
export const useMyLessons = (memberId: string | undefined, page: number) => {
  return useQuery<LessonsPage | undefined, Error>(
    ["my_lesson_info", memberId, page],
    async () => {
      if (memberId) return await HttpService.getMyLessons(memberId, page);
      else return undefined;
    },
    {
      retry: false,
      enabled: memberId != undefined,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );
};
export const useAllLessons = (memberId: string | undefined) => {
  return useQuery<Lesson[], Error>(
    ["my_all_lesson_info", memberId],
    async () => {
      if (memberId) return await HttpService.getAllLessons(memberId);
      else return [];
    },
    {
      retry: false,
    }
  );
};
export const useClassLessons = (classId: string | undefined) => {
  return useQuery<Lesson[], Error>(
    ["class_lesson_info", classId],
    async () => {
      if (classId) return await HttpService.getClassLessons(classId);
      else return [];
    },
    {
      retry: false,
    }
  );
};
export const useGetActivity = (activityId: string | undefined) => {
  return useQuery<Activity | undefined, Error>(
    ["get_activity_cell", activityId],
    async () => {
      if (activityId) return await HttpService.getActivity(activityId);
      else return undefined;
    },
    {
      retry: false,
    }
  );
};
export const useGSignup = () => {
  return useMutation<LoginRes, Error, string>(
    "signup",
    async (formdata) => {
      return await HttpService.googleSignup(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useGSignin = () => {
  return useMutation<LoginRes, Error, string>(
    "signin",
    async (formdata) => {
      return await HttpService.googleSignin(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useSendEmail = () => {
  return useMutation(async (email: string) => {
    return await HttpService.getCodeMail(email);
  });
};
export const useCheckCodeMail = () => {
  return useMutation<any, Error, MemberCodeDto>(
    "checkCodeMail",
    async (formData) => {
      return await HttpService.checkCodeEmail(formData);
    },
    {
      retry: false,
    }
  );
};
export const useCountries = () => {
  return useQuery<ICountry[], Error>(
    "get_countries",
    async () => {
      const res = await HttpService.getCountries();
      return res.data;
    },
    {
      retry: false,
    }
  );
};
export const useMemberInfo = (memberId: string | undefined) => {
  return useQuery<Member | undefined, Error>(
    ["get_member_info", memberId],
    async () => {
      if (memberId) {
        const res = await HttpService.getUserInfo(memberId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
    }
  );
};
export const useAllFolders = (memberId: string | undefined) => {
  return useQuery<Folder[], Error>(
    ["get_all_folders", memberId],
    async () => {
      if (memberId) {
        const res = await HttpService.getAllFolders(memberId);
        return res;
      } else {
        return [];
      }
    },
    {
      retry: false,
      enabled: false,
    }
  );
};
export const useAllClass = (memberId: string | undefined) => {
  return useQuery<Classes[], Error>(
    ["get_all_classes", memberId],
    async () => {
      if (memberId) {
        const res = await HttpService.getAllClasses(memberId);
        return res;
      } else {
        return [];
      }
    },
    {
      retry: false,
      enabled: false,
    }
  );
};
export const useGetAdaptInfo = (activityId: string | undefined) => {
  return useQuery<AdaptInfo | undefined, Error>(
    ["get_adapt_info", activityId],
    async () => {
      if (activityId) {
        const res = await HttpService.getAdapts(activityId);
        return res;
      } else return undefined;
    }
  );
};
export const useAdaptActivities = (
  activityId: string | undefined,
  page: number
) => {
  return useQuery<ActivitiesPage | undefined, Error>(
    ["get_adaptation_activitis", activityId, page],
    async () => {
      if (activityId) return await HttpService.getAdaptations(activityId, page);
      return undefined;
    },
    {
      retry: false,
    }
  );
};
export const useGetCourseInfo = (cId: string | undefined) => {
  return useQuery<Course | undefined, Error>(
    ["get_course_info", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getCourseInfo(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};

export const useUpdateDefaultImage = () => {
  return useMutation<string, Error, string>(
    ["update_image_default"],
    async (body) => {
      return await HttpService.updateImageCourseInfo(body);
    },
    {
      retry: false,
    }
  );
};

export const useGetCollectionInfo = (cId: string | undefined) => {
  return useQuery<FolderItemDto | undefined, Error>(
    ["get_folder_info", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getCollectionInfo(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};

export const useMyCourse = () => {
  return useQuery<Course[] | undefined, Error>(
    ["get_my_courses"],
    async () => {
      const res = await HttpService.getMyCourses();
      return res;
    },
    {
      retry: false,
    }
  );
};
export const useMyCollection = () => {
  return useQuery<FolderItem[] | undefined, Error>(
    ["get_my_collection"],
    async () => {
      const res = await HttpService.getMyCollection();
      return res;
    },
    {
      retry: false,
    }
  );
};
export const useGetUnitInfo = (cId: string | undefined) => {
  return useQuery<Units | undefined, Error>(
    ["get_unit_info", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getUnitInfo(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};
export const useGetUnitByCourse = (cId: string | undefined) => {
  return useQuery<Units[], Error>(
    ["get_units_by_course", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getUnitsByCourse(cId);
        return res;
      } else {
        return [];
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};

export const useGetChildrenByCollection = (cId: string | undefined) => {
  return useQuery<CollectionItem, Error>(
    ["get_children_by_collection", cId],
    async () => {
      const res = await HttpService.getChilrenByCollection(cId);
      return res;
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};

export const useGetDataDashboard = () => {
  return useQuery<any, Error>(
    ["get_data_dashboard"],
    async () => {
      const res = await HttpService.getDataDashboard();
      return res;
    },
    {
      retry: false,
    }
  );
};

export const useGetLessonByUnit = (cId: string | undefined) => {
  return useQuery<LessonInfoDto[], Error>(
    ["get_lesson_by_unit", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getLessonsByUnit(cId);
        return res;
      } else {
        return [];
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
      cacheTime: 10,
      refetchOnWindowFocus: false,
    }
  );
};

export const useLessonOrderByUnit = () => {
  return useMutation<any, Error, string[]>(
    ["update_order_lesson"],
    async (cIds: string[]) => {
      return await HttpService.updateOrderLessonInfo(cIds);
    },
    {
      retry: false,
    }
  );
};

export const useUnitOrderByUnit = () => {
  return useMutation<any, Error, string[]>(
    ["update_order_unit"],
    async (cIds: string[]) => {
      return await HttpService.updateOrderUnitInfo(cIds);
    },
    {
      retry: false,
    }
  );
};

export const useGetActivityMapping = (cId: string | undefined) => {
  return useQuery<ActivityMappingDto | undefined, Error>(
    ["get_activity_info_mapping", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getActivityMappingLesson(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
    }
  );
};

export const useGetLessonByUnitPublish = (
  cId: string | undefined,
  lessonId: string | undefined
) => {
  return useQuery<LessonInfoDto[], Error>(
    ["get_lesson_by_unit_publish", cId, lessonId],
    async () => {
      if (cId && isGuidValidate(cId) && lessonId && isGuidValidate(lessonId)) {
        const res = await HttpService.getLessonsByUnitPublish(cId, lessonId);
        return res;
      } else {
        return [];
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
      cacheTime: 10,
      refetchOnWindowFocus: false,
    }
  );
};
export const useGetLessonById = (cId: string | undefined) => {
  return useQuery<Lesson | undefined, Error>(
    ["get_lesson_by_id", cId],
    async () => {
      if (cId && isGuidValidate(cId)) {
        const res = await HttpService.getLessonInfo(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
      cacheTime: 10,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetLessonSectionById = (cId: string | undefined) => {
  return useQuery<LessonSection | undefined, Error>(
    ["get_lesson_sections_by_id", cId],
    async () => {
      if (cId) {
        const res = await HttpService.getLessonSectionById(cId);
        return res;
      } else {
        return undefined;
      }
    },
    {
      retry: false,
      enabled: cId != undefined,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};

export const useGetFollowUsers = () => {
  return useQuery<Member[], Error>(
    ["get_follow_users"],
    async () => {
      const res = await HttpService.getFollowUsers();
      return res;
    },
    {
      retry: false,
    }
  );
};
export const useCourseMembers = (uuid: string | undefined) => {
  return useQuery<CourseMembers[], Error>(
    ["get_course_members", uuid],
    async () => {
      if (uuid) {
        const res = await HttpService.getCourseMemberUsers(uuid);
        return res;
      }
      return [];
    },
    {
      retry: false,
      enabled: uuid != undefined,
    }
  );
};
export const useCollectionMembers = (uuid: string | undefined) => {
  return useQuery<CollectionMembers[], Error>(
    ["get_collection_members", uuid],
    async () => {
      if (uuid) {
        const res = await HttpService.getCollectionMemberUsers(uuid);
        return res;
      }
      return [];
    },
    {
      retry: false,
      enabled: uuid != undefined,
    }
  );
};
export const useCourseLevel = (uuid: string | undefined) => {
  return useQuery<number | undefined, Error>(
    ["get_course_level", uuid],
    async () => {
      if (uuid) {
        const res = await HttpService.getCourseLevel(uuid);
        return res;
      }
      return undefined;
    },
    {
      retry: false,
      enabled: uuid != undefined,
    }
  );
};
export const useSaveCourseInfo = () => {
  return useMutation<any, Error, string>(
    ["save_course_info"],
    async (formData: string) => {
      return await HttpService.saveCourseInfo(formData);
    },
    {
      retry: false,
    }
  );
};

export const useSaveCollectionInfo = () => {
  return useMutation<any, Error, string>(
    ["save_collection_info"],
    async (formData: string) => {
      return await HttpService.saveCollectionInfo(formData);
    },
    {
      retry: false,
    }
  );
};

export const useSaveUnit = () => {
  return useMutation<any, Error, string>(
    ["save_unit_info"],
    async (formData: string) => {
      return await HttpService.saveUnitInfo(formData);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteUnit = () => {
  return useMutation<any, Error, string>(
    ["delete_unit_info"],
    async (uuid: string) => {
      return await HttpService.deleteUnit(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteActivityCollection = () => {
  return useMutation<any, Error, string>(
    ["delete_activity_collection"],
    async (uuid: string) => {
      return await HttpService.deleteActivityCollection(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useToggleShownActivity = () => {
  return useMutation<Activity, Error, string>(
    ["toggle_activity_shown"],
    async (uuid: string) => {
      return await HttpService.toggleShowActivity(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteCourse = () => {
  return useMutation<any, Error, string>(
    ["delete_course_info"],
    async (uuid: string) => {
      return await HttpService.deleteCourse(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteCollection = () => {
  return useMutation<any, Error, string>(
    ["delete_collection_info"],
    async (uuid: string) => {
      return await HttpService.deleteCollection(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteLessonInfo = () => {
  return useMutation<any, Error, string>(
    ["delete_lesson_info"],
    async (uuid: string) => {
      return await HttpService.deleteLesson(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useRegCourseMember = () => {
  return useMutation<any, Error, string>(
    ["reg_course_member"],
    async (formData: string) => {
      return await HttpService.regCourseMember(formData);
    },
    {
      retry: false,
    }
  );
};

export const useRegCollectionMember = () => {
  return useMutation<any, Error, string>(
    ["reg_collection_member"],
    async (formData: string) => {
      return await HttpService.regCollectionMember(formData);
    },
    {
      retry: false,
    }
  );
};

export const useAssignActivity = () => {
  return useMutation<any, Error, string>(
    ["assign_activity_data"],
    async (formData: string) => {
      return await HttpService.assignActivityForLesson(formData);
    },
    {
      retry: false,
    }
  );
};

export const useDeleteCourseMember = () => {
  return useMutation<any, Error, string>(
    ["delete_member_role"],
    async (uuid: string) => {
      return await HttpService.deleteMemberRole(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useDeleteCollectionMember = () => {
  return useMutation<any, Error, string>(
    ["delete_member_role"],
    async (uuid: string) => {
      return await HttpService.deleteCollectionMemberRole(uuid);
    },
    {
      retry: false,
    }
  );
};
export const useUpdateLessonSectionInfo = () => {
  return useMutation<any, Error, string>(
    ["update_lesson_section_info"],
    async (formData: string) => {
      return await HttpService.updateSectionInfo(formData);
    },
    {
      retry: false,
    }
  );
};
export const useQueryChat = () => {
  return useMutation<any, Error, string>(
    "query_chat_content",
    async (formdata) => {
      return await HttpService.queryActivityContent(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useGetPromptContent = () => {
  return useQuery<Prompt, Error>(
    ["get_prompt_content"],
    async () => {
      return await HttpService.getPromptContent();
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};
