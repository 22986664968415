import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Card, Container } from "react-bootstrap";
import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import { AuthForm, FormData } from "../components/AuthFormLogin";
import { useCheckCodeMail, useGSignup, useSendEmail } from "../services/Query";
import { MemberCodeDto } from "../models";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { useRecoilState } from "recoil";
import { UserInfo } from "../store";
import TokenService from "../services/TokenService";
import { AxiosError } from "axios";
import HttpService from "../services/HttpServices";

interface tokenType {
  token: string;
}
export const LoginWithEmail = () => {
  const navigate = useNavigate();
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [alertVariant, setAlertVariant] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState<string | null>(null);
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const { isLoading: loading, mutate: SendEmail } = useSendEmail();
  const { mutate: checkCodeMail,isLoading: codeloading, } = useCheckCodeMail();
  const { mutate: onGSignup } = useGSignup();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();
  useEffect(() => {
    const time = setTimeout(() => {
      setAlertVariant(null);
    }, 3000);
    return () => clearTimeout(time);
  }, [alertVariant]);
  const handleExist = async (email: string) => {
    const data = {
      Email: email,
    };
    onGSignup(JSON.stringify(data), {
      onSuccess: (res) => {
        const { member, token } = res;
        TokenService.updateLocalAccessToken(token);
        setUserinfo(member);
        navigate("/dashboard");
      },
      onError: (err) => {
        const res = err as AxiosError;
        const tokenObj = res.response?.data as tokenType;
        if (tokenObj) {
          HttpService.verifyUser(tokenObj.token).then((res) => {
            setUserinfo(res.data);
          });
        }
      },
    });
  };
  const onSubmit = async (data: FormData) => {
    if (!isEmailSent && data.email) {
      SendEmail(data.email, {
        onSuccess: (res) => {
          if (res) {
            handleExist(data.email);
          } else {
            reset();
            setEmailSent(data.email);
            setIsEmailSent(true);
            setAlertVariant("success");

          }
        },
        onError: () => {
          setAlertVariant("danger");
        },
      });
    } else {
      let objNew: MemberCodeDto = {
        Email: String(emailSent),
        Code: data.code,
      };
      checkCodeMail(objNew, {
        onSuccess: (response) => {
          if (response) {
            handleExist(String(emailSent));
          } else {
            setAlertVariant("danger");
          }
        },
        onError: (error) => {
          setAlertVariant("danger");
        },
      });
    }
  };
  const getAlertMessage = (variant: string | null): string => {
    switch (variant) {
      case "success":
        return "Email sent successfully! Please check your inbox for the verification code.";
      case "danger":
        return isEmailSent ? "Invalid code" : "Error sending email.";
      default:
        return "";
    }
  };

  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <button
          className="back_btn"
          onClick={() => {
            navigate("/login", { replace: true });
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <Container className="pt-5">
          <Card className="sign_card mx-auto">
            <Card.Header>Sign in with Email</Card.Header>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="mt-3 mb-3">
                    <AuthForm
                      loading={isEmailSent ? codeloading : loading  }
                      formType={isEmailSent ? "code" : "email"}
                      onSubmit={onSubmit}
                      register={register}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      reset={reset}
                    />
                  </div>
                  {alertVariant && (
                    <Alert variant={alertVariant}>{getAlertMessage(alertVariant)}</Alert>
                  )}
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
